import React, { Component } from "react"

export default class branchFeedback extends Component {
  componentWillMount() {
    if (typeof window !== `undefined`) {
      setTimeout(() => {
        window.location.replace(
          `http://private.tascombank.ua/branch-feedback${window.location.search}`
        )
      })
    }
  }

  render() {
    return <div></div>
  }
}
